.mostViewdReportsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    @media (max-width: 768px) {
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;