.custom-radio-group-report .ant-radio-button-wrapper {
  border-color: #9c9c9c !important;
}

.custom-radio-group-report .ant-radio-button-wrapper-checked {
  background-color: #1E0C68 !important; 
  border-color: #1E0C68 !important;
  color: #fff !important; 
}

.custom-radio-group-report .ant-radio-button-wrapper:hover {
  background-color: #fff !important;
  color: #1E0C68 !important;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;