@import '/src/__variables.less';

.carouselWrapper {
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  font-size: 24px;
  color: #000;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevArrow {
  left: 16px;
}

.nextArrow {
  right: 16px;
}

.item {
  display: inline-flex; 
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 24px; 
  background: linear-gradient(110deg, rgba(255, 255, 255, 0.8), rgba(173, 216, 230, 0.8));
  border-radius: 16px;
  // clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%); 
  z-index: 1;
}

.avatar {
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 3px solid white;
  z-index: 2;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.fullName {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-dark-blue)!important;
  text-align: left;
}

.companyName {
  color: var(--color-blue)!important;
  text-align: left;
}

.content {
  font-size: 16px;
  text-align: left;
  color: var(--color-dark-blue)!important;
}

.carouselWrapper {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 660px;
}

.carouselContentStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  min-height: 160px;
  text-align: center;
}

.carouselWrapper .slick-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex !important;
  justify-content: center;
  gap: 8px;
}

.carouselWrapper .slick-dots li {
  width: 12px;
  height: 12px;
}

.carouselWrapper .slick-dots li button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #000 !important;
  transition: background-color 0.3s;
}

.carouselWrapper .slick-dots li.slick-active button {
  background-color: var(--color-dark-blue) !important;
}

.successImage {
  width: 100%;
  height: auto;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;