@import '/src/__variables.less';

.popoverContent {
    button {
      color: var(--color-dark);
      padding-inline: 0;
  
      svg {
        font-size: 1.25rem;
      }
    }
  }

.actionButton {
    height: 35px;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;