.item {
  padding: 4px 0;

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 0;
    border: 1px solid transparent;
    border-radius: 4px;
    gap: 8px;

    .icon {
      font-size: 1.25rem;
      color: var(--color-gray);
      cursor: move;
    }

    .content {
      width: 100%;
      display: flex;
      gap: 15px;
      align-items: center;
      overflow: hidden;

      .label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;