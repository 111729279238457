@import '/src/__variables.less';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.popoverContent {
  button {
    color: var(--color-dark);
    padding-inline: 0;

    svg {
      font-size: 1.25rem;
    }
  }
}

.imageWrapper {
  overflow: hidden;
  border-radius: 50%;
}

.content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: visible;

  -ms-overflow-style: scrollbar;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .content {
    &::-webkit-scrollbar {
      display: block;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-gray);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-white);
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;