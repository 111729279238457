.cost-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.custom-table .ant-table-content {
    overflow-x: auto !important;
}

.custom-table .ant-table-thead>tr>th,
.custom-table .ant-table-tbody>tr>td,
.custom-table .ant-table-summary>tr>td {
    padding: 6px 10px !important;
    white-space: nowrap;
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;