.category {
  padding: 4px 12px;
  white-space: nowrap;
  background: var(--color-light-light-gray);
  color: var(--color-gray);
  border-radius: 4px;
  cursor: pointer;

  &.active {
    color: var(--color-white);
    background: var(--color-dark-blue);
  }
}

.scriptsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.scriptDetails {
  min-width: 300px;
  max-width: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;