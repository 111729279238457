@import '~antd/dist/antd.less';
@import './__variables.less';

:root,
[data-theme='default'] {
  --color-box-shadow: #0000000f;
  --color-border: #f1f1f1;
  --color-line-table: #f8f5f5;
  --color-white: #ffffff;
  --color-white-8: #ffffffcc;
  --color-dark: #464646;
  --color-gray: #8d94a2;
  --color-gray-8: #8d94a2cc;
  --color-hover-gray: #fbfbfb;
  --color-dark-blue: #1e0c68;
  --color-hover-blue: #3b288c;
  --color-light-light-gray: #f5f5f9;
  --color-blue: #007dfe;

  --color-tag-light-blue: #edf2fa;
  --color-tag-light-yellow: #fefbf1;
  --color-tag-light-green: #edfaf0;
  --color-tag-light-red: #faedf0;
  --color-tag-light-purple: #f8f0ff;
  --color-tag-light-gray: #f1f1f1;

  --color-tag-blue: #3656af;
  --color-tag-yellow: #e5d640;
  --color-tag-green: #36af51;
  --color-tag-red: #de2626;
  --color-tag-purple: #7532a8;
  --color-tag-gray: #8d94a2;
  --color-highlight-yellow: #fff9e5;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100%;
}

// disabled error overlay
body {
  iframe {
    display: none;
  }
}

.page-container {
  width: 100%;
  height: 100%;
  max-width: var(@max-width-lg);
  display: flex;
}

html {
  min-height: 100vh;
  font-size: 16px;
}

a {
  color: var(--color-dark-blue);
  font-weight: 500;

  &.ant-btn-lg {
    padding: 6.4px 14px !important;
    display: flex;
    align-items: center;
  }

  &.ant-btn-icon-only {
    &.ant-btn-lg {
      padding: 6.4px 0 !important;
      justify-content: center;
    }
  }
}

.content {
  &-lg {
    max-width: @max-width-lg;
    margin: auto;
    width: 100%;
  }

  &-md {
    max-width: @max-width-md;
    margin: auto;
    width: 100%;
  }
}

.table {
  &-without-border {
    .ant-table-container {
      border: 0;
    }
  }

  &-only {
    &-header {
      .ant-table-tbody {
        display: none;
      }
    }

    &-content {
      .ant-table-thead {
        visibility: collapse;
      }
    }
  }
}

h1 {
  &.ant-typography {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 600;
    margin: 0;
  }
}

article {
  &.ant-typography {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 400;
    margin: 0;
  }
}

h2 {
  &.ant-typography {
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 600;
    margin: 0 !important;
  }
}

h3 {
  &.ant-typography {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 500;
    margin: 0 !important;
  }
}

h4 {
  &.ant-typography {
    font-size: 0.875rem;
    line-height: 1.25;
    font-weight: 500;
    margin: 0 !important;
  }
}

p,
span,
input::placeholder {
  &.ant-typography {
    font-size: 1em;
    line-height: 1.25;
    font-weight: 400;
  }
}

.color {
  &-gray {
    color: var(--color-gray) !important;
  }

  &-gray8 {
    color: var(--color-gray-8) !important;
  }

  &-dark {
    color: var(--color-dark) !important;

    &-blue {
      color: var(--color-dark-blue) !important;
    }
  }

  &-green {
    color: var(--color-tag-green) !important;
  }

  &-red {
    color: var(--color-tag-red) !important;
  }

  &-blue {
    color: var(--color-blue) !important;
  }
}

.bg {
  &-light-gray {
    background-color: var(--color-light-light-gray) !important;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movingTo {
  min-width: 200px;
}

.input-row-with-button {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-form-item {
    flex: 1 1 auto;
  }

  .ant-btn-icon-only {
    flex: 0 0 40px;
    margin-top: 5px;
  }
}

.btn-default-dark-blue {
  color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);

  &:hover {
    color: var(--color-hover-blue);
    border-color: var(--color-hover-blue);
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.line-through {
  text-decoration: line-through;
}

.flex {
  &-row {
    display: flex;
  }

  &-col {
    display: flex;
    flex-direction: column;
  }

  &-justify {
    &-space-between {
      justify-content: space-between;
    }

    &-center {
      justify-content: center;
    }

    &-end {
      justify-content: flex-end;
    }
  }

  &-align {
    &-start {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-end {
      align-items: flex-end;
    }

    &-self {
      &-end {
        align-self: flex-end;
      }
    }
  }

  &-1 {
    flex: 1 1 auto;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.cursor {
  &-default {
    cursor: default;
  }

  &-pointer {
    cursor: pointer;
  }
}

.position {
  &-relative {
    position: relative;
  }
}

.text-align-right {
  text-align: right;
}

.gap {
  &-4 {
    gap: 4px;
  }

  &-8 {
    gap: 8px;
  }

  &-10 {
    gap: 10px;
  }

  &-12 {
    gap: 12px;
  }

  &-16 {
    gap: 16px;
  }

  &-20 {
    gap: 20px;
  }

  &-24 {
    gap: 20px;
  }

  &-40 {
    gap: 20px;
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }

  &-top {
    &-20 {
      padding-top: 20px;
    }
  }

  &-block {
    &-0 {
      padding-block: 0 !important;
    }

    &-10 {
      padding-block: 10px;
    }

    &-20 {
      padding-block: 20px;
    }
  }

  &-inline {
    &-0 {
      padding-inline: 0 !important;
    }

    &-10 {
      padding-inline: 10px;
    }

    &-20 {
      padding-inline: 20px;
    }
  }
}

.w {
  &-100 {
    width: 100%;
    max-width: 100%;
  }

  &-fit-content {
    width: fit-content;
    max-width: fit-content;
  }

  &-min {
    &-150 {
      min-width: 150px;
    }

    &-200 {
      min-width: 200px;
    }
  }

  &-max {
    &-40 {
      max-width: 40%;
    }
  }

  &-auto {
    width: auto !important;
  }
}

.h {
  &-100 {
    height: 100%;
  }
}

.mh {
  &-200 {
    min-height: 200px;
  }
}

.br {
  &-4 {
    border-radius: 2px;
  }
}

.custom-input-form-item {
  height: 100%;
  margin: 0;

  .ant-form-item-row {
    height: 100%;
  }

  .ant-form-item-control {
    display: flex;
    justify-content: flex-end;
  }
}

.fs {
  &-075 {
    font-size: 0.75rem !important;
  }

  &-0875 {
    font-size: 0.875rem !important;
  }

  &-1 {
    font-size: 1rem !important;
  }

  &-125 {
    font-size: 1.25rem !important;
  }

  &-15 {
    font-size: 1.5rem !important;
  }

  &-2 {
    font-size: 2rem !important;
  }

  &-25 {
    font-size: 2.5rem !important;
  }
}

.fw {
  &-400 {
    font-weight: 400 !important;
  }

  &-500 {
    font-weight: 500 !important;
  }

  &-600 {
    font-weight: 600 !important;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }

  &-l {
    &-auto {
      margin-left: auto;
    }
  }

  &-b {
    &-20 {
      margin-bottom: 20px;
    }
  }
}

.title-input {
  color: var(--color-dark);
  font-size: 2.25rem;
  border: 0;
  height: 1.2em;
  line-height: 1.2;
  padding-left: 0;

  &:focus {
    box-shadow: none;
  }

  &.ant-input-disabled {
    color: var(--color-dark);
    background: transparent;
    cursor: default;
  }
}

.select-popup {
  min-width: 450px !important;
  max-width: 100% !important;
}

.table-form-item {
  margin: 0;

  .ant-form-item-control-input {
    min-height: 0;
  }
}

.chart-wrapper {
  width: 100%;
  height: 350px;
  cursor: default;
}

.ws {
  &-nowrap {
    white-space: nowrap;
  }
}

.label {
  &-gray {
    .ant-form-item-label {
      label {
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        color: var(--color-gray) !important;
      }
    }
  }

  &-dark {
    .ant-form-item-label {
      label {
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        color: var(--color-dark) !important;
      }
    }
  }
}

.modal {
  .ant-modal {
    width: fit-content !important;
    max-width: 440px;
  }
}

.checkbox-large {
  align-items: flex-start !important;

  .ant-checkbox {
    top: 0.2em;

    &-inner {
      width: 24px;
      height: 24px;

      &:after {
        left: 30%;
      }
    }
  }
}

.border {
  &-right {
    border-right: 1px solid var(--color-border);
  }

  &-bottom {
    border-bottom: 1px solid var(--color-border);
  }

  &-left {
    border-left: 1px solid var(--color-border);
  }

  &-top {
    border-top: 1px solid var(--color-border);
  }
}

.d {
  &-lg {
    &-none {
      display: none !important;
    }

    &-grid {
      display: grid !important;
    }

    &-flex {
      display: flex !important;
    }
  }

  &-none {
    display: none !important;
  }
}

.autocomplete-borderless {
  font-size: inherit !important;
  color: inherit !important;

  .ant-select-selector {
    padding: 0 !important;
    background: transparent !important;
    border: 0 !important;
    height: 2em !important;

    .ant-select-selection-search {
      left: 0 !important;
      right: 0 !important;
    }

    input {
      height: 100% !important;
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.steps {
  .ant-steps-item-tail {
    &:after {
      background: var(--color-blue) !important;
    }
  }

  .ant-steps-icon-dot {
    background: var(--color-blue) !important;
  }
}

.select-padding-0 {
  .ant-select-selector {
    padding: 0 !important;
  }
}

.popover-content {
  max-height: 200px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.popover-select {
  padding: 0;

  .ant-popover-inner-content {
    padding: 4px 0;

    .option-row {
      padding: 5px 12px;
      font-size: 0.875rem;
      cursor: pointer;
      height: 2rem;
      min-width: 180px;

      &:not(&.active) {
        &:hover {
          background: var(--color-light-light-gray);
        }
      }

      &.active {
        font-weight: 600;
      }
    }
  }
}

.tooltip {
  z-index: 998;
  box-shadow: 0 0 10px -5px var(--color-dark);

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: var(--color-white);
    position: absolute;
    z-index: 999;
    left: calc(50% - 5px);
    top: 3px;
    box-shadow: -2px -2px 5px -3px var(--color-dark);
    border-radius: 2px;
  }

  &.arrow-left {
    &:before {
      left: 25px;
    }
  }

  &.arrow-right {
    &:before {
      left: auto;
      right: 25px;
    }
  }

  &-content {
    padding: 8px 12px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 1000;
    border-radius: 8px;
  }
}

.slider {
  &-dots {
    &-less {
      .ant-slider-dot {
        display: none;
      }
    }
  }
}

.slider {
  &-dots {
    &-refinance {
      .ant-slider-dot {
        display: block;
        border-color: #91caff;
      }
      .ant-slider-handle {
        box-shadow:0px 0px 0px 1px rgb(30, 12, 104) !important;
      }
    }
  }
}

.search-input {
  .ant-input-group {
    .ant-input-group-addon:last-child {
      padding-right: 10px;
    }
  }
}

.overflow {
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-auto {
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-hidden {
    overflow: hidden;
  }
}

.ant {
  &-switch {
    &-checked {
      background: #007dfe;
    }
  }

  &-divider {
    &-horizontal {
      margin: 20px 0;
    }

    &-vertical {
      margin: 0 20px;
    }
  }

  &-select {
    &-item {
      &-option {
        &-active {
          background: var(--color-light-light-gray) !important;
        }

        &-selected {
          background: inherit !important;

          .optionChecked {
            display: block;
          }
        }
      }
    }
  }

  &-pagination {
    .pagination-item {
      padding: 10px 16px;
      border: 1px solid var(--color-border);
      border-right: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      color: var(--color-dark);
    }

    &-prev {
      .pagination-item {
        border-radius: 8px 0 0 8px;
      }
    }

    &-next {
      .pagination-item {
        border-radius: 0 8px 8px 0;
        border-right: 1px solid var(--color-border);
      }
    }

    &-disabled {
      .pagination-item {
        background: var(--color-hover-gray);
      }
    }

    &-item {
      border: 0;

      &-active {
        border: 0;

        .pagination-item {
          background: var(--color-light-light-gray);
        }
      }
    }
  }

  &-notification {
    &-notice {
      width: fit-content;
      white-space: nowrap;

      &-with-icon {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-icon {
        position: relative;
        font-size: 2.5rem;
      }

      &-message {
        margin: 0 !important;
        white-space: nowrap;
        padding-right: 0 !important;
      }

      &-close {
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        font-size: 1.5rem;
      }
    }
  }

  &-table {
    &-container {
      border-radius: 8px;
      border: 1px solid var(--color-border);
    }

    .table-hover-actions {
      padding-left: 20px;
    }

    &-thead {
      .ant-table-cell {
        color: var(--color-gray);
        text-transform: uppercase;

        &:before {
          content: none !important;
        }
      }
    }
  }

  &-progress {
    &-bg {
      background-color: var(--color-dark-blue);
    }

    &-status {
      &-success {
        .ant-progress-bg {
          background-color: var(--color-dark-blue);
        }
      }
    }
  }

  &-card {
    &-body {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-bordered {
      border-color: var(--color-border);
      border-radius: 12px;
    }
  }

  &-space {
    &-vertical {
      width: 100%;
    }
  }

  &-radio {
    &-group {
      width: 100%;
    }

    &-wrapper {
      display: flex;
      margin: 0;

      .ant-radio-inner {
        width: 20px;
        height: 20px;
      }

      span {
        &:last-child {
          display: block;
          flex: 1 1 auto;
        }
      }
    }

    &-checked {
      .ant-radio-inner {
        &:after {
          width: 20px;
          height: 20px;
          margin-top: -10px;
          margin-left: -10px;
          transform: scale(0.7);
        }
      }
    }

    &-button {
      &-wrapper {
        color: var(--color-gray) !important;
        border-color: var(--color-border) !important;
        padding: 0 10px !important;

        &:focus-within {
          box-shadow: none !important;
        }

        &-checked {
          background-color: var(--color-border) !important;
          color: var(--color-dark) !important;
          border-color: var(--color-border) !important;
        }
      }
    }
  }

  &-tabs {
    &.steps {
      height: 100%;
      display: grid;
      grid-template-rows: 1fr min-content;

      .ant-tabs-nav {
        &:before {
          content: none;
        }

        .ant-tabs-tab {
          width: 36px;
          position: relative;
          pointer-events: none;

          &:before {
            width: 100%;
            height: 3px;
            border-radius: 1px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: var(--color-border);
            pointer-events: none;
          }
        }

        .ant-tabs-ink-bar {
          height: 3px;
          border-radius: 1px;
        }
      }

      .ant-tabs-content-holder {
        display: grid;

        .ant-tabs-content {
          align-self: center;
        }
      }
    }
  }

  &-menu {
    background: none;
    border: none;

    &-item {
      height: 44px;
      padding: 0 !important;
      color: var(--color-gray);
      font-size: 1em;

      .ant-menu-title-content {
        font-size: 1rem;
      }

      &:active {
        background: none !important;
      }

      a {
        color: inherit;

        &:before {
          content: none;
        }
      }

      &-selected {
        background: none !important;
        color: var(--color-dark-blue);

        &:after {
          content: none !important;
        }
      }
    }
  }

  &-btn {
    > .anticon {
      line-height: 0;
    }

    &-primary {
      background: var(--color-dark-blue);
      color: var(--color-white);
      border: 0;

      &:hover {
        background: var(--color-hover-blue);
        color: var(--color-white);
      }

      &:disabled {
        background: var(--color-gray);
        color: var(--color-white);
        pointer-events: none;
      }
    }

    &-ghost {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      box-shadow: none;
      color: var(--color-gray);

      &:after {
        all: unset;
      }

      &:hover {
        color: var(--color-dark-blue);
      }
    }

    &-lg {
      height: 44px;
      font-size: 1em;
    }

    &-icon {
      &-only {
        width: fit-content !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  &-form {
    &-vertical {
      .error {
        margin: -6px 0 28px;
      }

      label {
        &:before {
          display: none !important;
        }
      }
    }

    &-item {
      &-label {
        > label {
          font-weight: 500;
          color: var(--color-dark);
        }
      }
    }
  }

  &-checkbox {
    top: 0;

    &-wrapper {
      top: 0;
      font-size: 1rem !important;
      display: flex;
      align-items: center;
    }
  }

  &-input {
    &-lg {
      min-height: 40px;
    }

    &-search {
      .ant-input-group {
        > .ant-input,
        > .ant-input-affix-wrapper {
          padding-block: 0;
          background: var(--color-light-light-gray);
          border: 0;
          border-radius: 8px 0 0 8px;

          .ant-input {
            background: transparent;

            &:focus {
              box-shadow: none;
              border-width: 0;
            }
          }

          &:focus {
            box-shadow: none;
          }

          &-focused {
            box-shadow: none;
            border-width: 0;
          }
        }

        .ant-input-search-button {
          background: var(--color-light-light-gray);
          border: 0;
          border-radius: 0 8px 8px 0 !important;
          box-shadow: none;
          padding: 0 10px;

          &:after {
            all: unset;
          }
        }
      }
    }

    &-password {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .ant-input {
        padding-left: 11px !important;
      }
    }

    &-status {
      &-error {
        border-color: rgb(217, 217, 217) !important;
        box-shadow: none !important;
      }
    }
  }

  &-picker {
    &-status {
      &-error {
        border-color: rgb(217, 217, 217) !important;
        box-shadow: none !important;
      }
    }
  }

  &-modal {
    &-confirm {
      &-title {
        color: var(--color-dark);
        font-size: 1.25rem;
      }

      &-content {
        color: var(--color-dark);
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: @max-width-md) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: @max-width-xs) {
  .d {
    &-m {
      &-none {
        display: none !important;
      }

      &-grid {
        display: grid !important;
      }

      &-flex {
        display: flex !important;
      }
    }
  }

  .gap {
    &-m {
      &-0 {
        gap: 0;
      }

      &-10 {
        gap: 10px;
      }
    }
  }

  .w {
    &-m {
      &-100 {
        width: 100%;
      }

      &-min {
        &-50 {
          min-width: 50px;
        }

        &-100 {
          min-width: 100px;
        }

        &-150 {
          min-width: 150px;
        }
      }

      &-max {
        &-none {
          max-width: none;
        }
      }
    }
  }

  .flex {
    &-m {
      &-row {
        display: flex;
      }

      &-col {
        display: flex;
        flex-direction: column;
      }

      &-justify {
        &-space-between {
          justify-content: space-between;
        }

        &-center {
          justify-content: center;
        }

        &-start {
          justify-content: flex-start;
        }

        &-end {
          justify-content: flex-end;
        }
      }

      &-align {
        &-start {
          align-items: flex-start;
        }

        &-center {
          align-items: center;
        }

        &-baseline {
          align-items: baseline;
        }

        &-end {
          align-items: flex-end;
        }

        &-self {
          &-end {
            align-self: flex-end;
          }
        }
      }

      &-1 {
        flex: 1 1 auto;
      }

      &-wrap {
        flex-wrap: wrap;
      }

      &-order {
        &-1 {
          order: 1;
        }

        &-2 {
          order: 2;
        }
      }
    }
  }

  .table-hover-actions {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-block: -4px;
  }

  .select-popup {
    min-width: 320px !important;
  }

  .chart-wrapper {
    height: 200px;
  }

  .text-m-align-left {
    text-align: left;
  }

  .custom-input-form-item {
    .ant-form-item-row {
      height: fit-content;
      flex-flow: column;
    }
  }
}

.ant-table-thead .ant-table-cell {
  background-color: #e5eeff !important;
}

.ant-list-item-meta-avatar {
  margin-right: 8px !important;
}

@media print {
  .chart-wrapper canvas {
    display: flex !important;
  }
}
@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;