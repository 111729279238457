.inputWrapper {
  input {
    background: none !important;
    border: 0;
    outline: none;

    &:focus-visible {
      border: 0;
      outline: none;
    }
  }
}

.bordered {
  input {
    height: 100%;
    font-size: 0.875rem;
    min-height: 2.728em;
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 0 11px;
    border-radius: 4px;

    &:hover {
      border-color: #352175;
    }

    &:focus {
      border-color: #352175;
      box-shadow: 0 0 0 2px rgba(30, 12, 104, 0.2);
      border-right-width: 1px;
      outline: 0;
    }
  }
}

.label {
  color: var(--color-gray);
  margin-left: 2px;
  white-space: nowrap;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;