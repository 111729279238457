@import '/src/__variables.less';

.table {
  margin-top: 20px;
  overflow: auto;
  display: flex;
  max-height: fit-content;
  transition: all ease-in-out 300ms;
  opacity: 1;

  &.hidden {
    max-height: 0;
    opacity: 0;
  }
}

.header {
  background: var(--color-tag-light-blue);
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-dark);
}

.footer {
  padding: 16px 50px;
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: @max-width-xs) {
  .footer {
    justify-content: flex-start;
    padding: 10px;
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;