.item {
  padding: 4px 0;

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 0;
    border: 1px solid transparent;
    border-radius: 4px;
    gap: 8px;

    &.draggable {
      padding: 8px;
      border-color: var(--color-border);
    }

    .icon {
      font-size: 1.25rem;
      color: var(--color-gray);
      cursor: move;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      overflow: hidden;

      .label {
        overflow: hidden;
        word-wrap: break-word;
        white-space: normal;
        text-overflow: ellipsis;
        flex: 1; /* Allow the label to take available space */
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px; /* Consistent spacing between buttons and switch */
        min-width: 120px; /* Ensures a fixed width for the actions container */
      }

      .buttons {
        display: flex;
        gap: 8px; /* Spacing between Edit and Delete buttons */
      }
    }
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;