@import '/src/__variables.less';

.wrapper {
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  min-width: 0;
}

.optionRow {
  display: flex;
  gap: 4px;
  padding: 8px 12px;

  .optionIndex {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-dark);
    line-height: 1.2;
  }

  .optionContent {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1 1 auto;

    .optionName {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--color-dark);
      line-height: 1.2;
    }

    .optionLocation {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--color-gray);
    }
  }

  .optionChecked {
    display: none;
    font-size: 1rem;
    color: var(--color-blue);
  }
}

@media screen and (max-width: @max-width-xs) {
  .wrapper {
    max-width: 100%;
  }
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;