@import '/src/__variables.less';

.header {
  width: 100%;
  padding: 32px;
  border-bottom: 1px solid var(--color-border);

  .avatarWrapper {
    border-radius: 50%;
    overflow: hidden;
  }
}

.sidebar {
  padding: 32px;
  border-right: 1px solid var(--color-border);
  height: 100%;
}

.formWrapper {
  padding: 32px;
  max-width: 400px;
}

@font-family: 'Poppins', sans-serif;@primary-color: #1E0C68;@border-radius-base: 4px;